import DefaultNavbar from 'components/DefaultNavbar';
import DefaultFooter from 'components/DefaultFooter';
import ScrollTopButton from 'components/landing/ScrollTopButton';
import 'assets/styles/entirePage.css';
import OurProcessesSection from 'components/about/OurProcessesSection';
import ViewOurWorkSection from 'components/about/ViewOurWorkSection';
import TechMarquee from 'components/about/TechMarquee';
import OurServices from 'components/about/OurServices';
import HeaderSection from 'components/HeaderSection';

/**
 * The about us page of the website.
 * @returns The about us page with its components.
 */
export default function AboutUs() {
	return (
		<div className='hideOverflow'>
			<div className='absolute w-full z-20'>
				<DefaultNavbar />
			</div>
			<main>
				<HeaderSection backgroundClass='about-us-background'
					pageTitle='Premium Software Solutions for Your Business'
					// eslint-disable-next-line max-len
					subtext={'Our specialized team is ready to handle any project thrown our way. From concept to deployment we\'re committed to bringing your ideas to life.\n\n Ready to start your next big project? Contact us for a quote today.'}
				/>
				{/* Insert new elements here */}
				<OurProcessesSection />
				<OurServices />
				<TechMarquee />
				<ViewOurWorkSection titleText="Don't just take our word for it." titleText2='See our portfolio for yourself.'/>
				<ScrollTopButton />
			</main>
			<DefaultFooter />
		</div>
	);
}
