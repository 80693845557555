import Card from '@material-tailwind/react/Card';
import H6 from '@material-tailwind/react/Heading6';
import Paragraph from '@material-tailwind/react/Paragraph';
import 'aos/dist/aos.css';
import React from 'react';

/**
 * Imports the required libraries and components.
 * @param root0 - The properties object.
 * @param root0.Icon - The icon component to be displayed.
 * @param root0.title - The title of the service card.
 * @param root0.description - The description of the service card.
 * @param [root0.delay] - The delay for the animation.
 * @param root0.strokeWidth - The stroke width of the icon.
 * @param [root0.aosType] - The type of animation.
 * @returns The service card component.
 */
export default function ServiceCard({ Icon, title, description, delay = 0, strokeWidth, aosType = 'fade' }) {

	return (
		<div data-aos={aosType} data-aos-delay={delay} style={{ height: 350 }} className='flex flex-initial w-full xl:w-50'>
			<Card className='py-0'>
				<div className='h-full flex flex-col gap-4 items-center py-0 px-4 text-center'>
					<div>
						<div className='h-10 w-full flex items-center justify-center pt-2'>
							<H6 color='gray'>{title}</H6>
						</div>
						{/* Render the passed Lucide Icon component */}
						{Icon && <Icon
							strokeWidth={strokeWidth ?? 1.3}
							className='w-full h-40 self-center text-zenith pt-2'
						/>}
					</div>
					<div className='w-full text-center md:text-left'>
						<Paragraph className='m-0 text-center' color='blueGray' >
							{description}
						</Paragraph>
					</div>
				</div>
			</Card>
		</div>
	);
}
