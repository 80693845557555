import DefaultNavbar from 'components/DefaultNavbar';
import DefaultFooter from 'components/DefaultFooter';
import ScrollTopButton from 'components/landing/ScrollTopButton';
import 'assets/styles/entirePage.css';
import 'assets/styles/background.css';
import ProjectHeaderSection from 'components/ProjectHeaderSection';
import ProjectDescriptionWorkingSection from 'components/projectdescription/ProjectDescriptionWorkingSection';
import ViewOurWorkSection from 'components/about/ViewOurWorkSection';
import projectData from 'components/projects/ProjectData.jsx';
import TechMarquee from 'components/about/TechMarquee';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import OurImpactSection from 'components/projects/OurImpactSection';

/**
 * The project description page of the website. Template for the project description pages.
 * @returns The project description page with its components.
 */
export default function ProjectDescription() {
	const [ searchParams ] = useSearchParams();
	const paramValue = searchParams.get('projectName');

	const [ project, setProject ] = useState(null);


	useEffect(() => {
		if (paramValue) {
			// Find the project by title matching paramValue
			const matchedProject = projectData.find(
				(proj) => {
					return proj.title.toLowerCase() === paramValue.toLowerCase();
				}
			);
			setProject(matchedProject || projectData[0]); // Handle case where project isn't found
		}
	}, [ paramValue ]);

	return (
		<div className='hideOverflow'>
			<div className='absolute w-full z-20'>
				<DefaultNavbar />
			</div>
			<main>
				{project ? (
					<>
						<ProjectHeaderSection
							backgroundClass={project.bgPic}
							pageTitle={project.title}
							subtext={'Project Overview'}
						/>
						<ProjectDescriptionWorkingSection
							aboutSubtext={project.aboutInfo}
							aboutBgPhoto={project.aboutBgPhoto}
							captionTitle={project.captionTitle}
							captionSubtext={project.captionSubtext}
						/>
						<TechMarquee />
						<OurImpactSection
							stepCardInfo={project.stepCardInfo}
						/>
						{project.inAppStore &&
						<ViewOurWorkSection
							titleText={project.appStoreText}
							titleText2={project.appStoreText2}
							buttonText={project.appStoreButtonText}
							link={project.appStoreLink}
							phonePictureSrc={project.appStorePhonePic}
						/>
						}
					</>
				) : (
					<p>Loading...</p>
				)}

				<ScrollTopButton />
			</main>
			<DefaultFooter />
		</div>
	);
}
