import Card from '@material-tailwind/react/Card';
import CardBody from '@material-tailwind/react/CardBody';
import H6 from '@material-tailwind/react/Heading6';
import { scrollToSection } from 'components/scrollToSection';
import 'assets/styles/statuscard.css';
import { Link, useLocation } from 'react-router-dom';

const colors = {
	blueGray: 'bg-blue-gray-500',
	gray: 'bg-gray-500',
	brown: 'bg-brown-500',
	deepOrange: 'bg-deep-orange-500',
	orange: 'bg-orange-500',
	amber: 'bg-amber-500',
	yellow: 'bg-yellow-600',
	lime: 'bg-lime-500',
	lightGreen: 'bg-light-green-500',
	green: 'bg-green-500',
	teal: 'bg-teal-500',
	cyan: 'bg-cyan-500',
	lightBlue: 'bg-light-blue-500',
	blue: 'bg-blue-500',
	indigo: 'bg-indigo-500',
	deepPurple: 'bg-deep-purple-500',
	purple: 'bg-purple-500',
	pink: 'bg-pink-500',
	red: 'bg-red-500',
};

/**
 * The component for the status card on the landing page.
 * @param props The props for the StatusCard component.
 * @param props.color The color of the card.
 * @param props.icon The icon of the card.
 * @param props.title The title of the card.
 * @param props.nav The navigation of the card.
 * @param props.delay The delay of the card.
 * @param props.link The link of the card. Optional.
 * @returns The Status Card Component.
 */
export default function StatusCard({ color, icon: Icon, title, nav, delay, link = '' }) {
	const location = useLocation();

	return (
		<Link
			data-aos='fade-up'
			data-aos-delay={delay}
			className='w-full md:w-4/12 pt-8 px-4 flex justify-center text-center padding-cards cursor-pointer'
			onClick={() => {
				return scrollToSection(nav);
			}}
			to={{
				pathname: location.pathname,
				search: location.search,
				hash: location.hash
			}}
			state={location.state}
			aria-disabled={link === '' ? 'true' : 'false'}
		>
			<Card className='grow:hover grow'>
				<CardBody>
					<div
						className={`p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-md rounded-full ${colors[color]}`}
					>
						<Icon className='text-white' strokeWidth={2}/>
					</div>
					<H6 color='gray'>{title}</H6>
				</CardBody>
			</Card>
		</Link>
	);
}
