import React from 'react';
import ProcessCard from 'components/about/ProcessCard';
import 'assets/styles/processesSection.css';
import 'aos/dist/aos.css';
import WhiteboardPhoto from 'assets/img/WhiteboardPhoto.png';
import TreTree from 'assets/img/TreTree.png';
import GroupAroundBryce from 'assets/img/GroupAroundBryce.png';
import FounderTreePic from 'assets/img/FounderTreePic.png';
import TitleBlurb from 'components/TitleBlurb';
import StatusCard from 'components/landing/StatusCard';
import { Cpu, Route, LayoutGrid } from 'lucide-react';


const processes = [
	{
		title: 'Discovery & Design',
		description: 'Our team collaborates with you to create detailed wireframes and designs, ensuring the final product aligns perfectly with your vision.',
		img: WhiteboardPhoto
	},
	{
		title: 'Implementation',
		description: 'Our agile approach & sophisticated tech stack allows for flexibility and regular updates throughout the development process.',
		img: FounderTreePic
	},
	{
		title: 'Deployment',
		description: 'We handle the complexities of deployment, ensuring your software runs smoothly on chosen platforms.',
		img: GroupAroundBryce
	},
	{
		title: 'Support',
		description: 'We provide ongoing maintenance, updates, and support to keep your software secure and up-to-date.',
		img: TreTree
	}
];

/**
 * This section contains four cards outlining our development process
 * @returns The rendered OurProcessesSection component.
 */
export default function OurProcessesSection() {

	/**
	 * Renders a process card with the given process data.
	 * @param process - The process data.
	 * @param index - The index of the process in the list.
	 * @returns The rendered process card.
	 */
	function renderProcess(process, index) {
		return <ProcessCard key={process.title} layout={index % 2 === 0 ? 'left' : 'right'} {...process} />;
	}

	return (
		<section id='process-section' className='pt-20 pb-20 -mt-52'>
			<div className='container max-w-7xl mx-auto px-4'>
				<div className='flex flex-wrap relative z-10 max-w-7xl mx-auto'>

					<StatusCard
						color='lightBlue'
						icon={Route}
						title='Our Process'
						nav='OurProcess'
						delay={0}
					>
					</StatusCard>

					<StatusCard
						color='lightBlue'
						icon={LayoutGrid}
						title='Our Services'
						nav='OurServices'
						delay={100}
					>
					</StatusCard>

					<StatusCard
						color='lightBlue'
						icon={Cpu}
						title='Tech We Use'
						nav='TechWeUse'
						delay={200}
					>
					</StatusCard>
				</div>

				<div className={'flex flex-col max-w-7xl mx-auto px-4 pt-20 items-center'}>

					<div className='OurProcess'/>

					<TitleBlurb
						title='Our Process'
						blurb='Take a look into our workflow, designed to take your project from initial concept to
						a fully realized, high-quality software solution.' />
					<div className={'flex flex-col flex-wrap md:flex-nowrap gap-8 md:gap-16 pt-8'}>
						{processes.map(renderProcess)}
					</div>
				</div>
			</div>
		</section>
	);
}
