import Card from "@material-tailwind/react/Card";
import CardImage from "@material-tailwind/react/CardImage";
import CardBody from "@material-tailwind/react/CardBody";
import Icon from "@material-tailwind/react/Icon";
import H4 from "@material-tailwind/react/Heading4";
import H6 from "@material-tailwind/react/Heading6";
import LeadText from "@material-tailwind/react/LeadText";
import Paragraph from "@material-tailwind/react/Paragraph";
import StatusCard from "components/landing/StatusCard";
import "aos/dist/aos.css";
import React from "react";
import { CodeXml, PieChart, User } from "lucide-react";


/**
 * The component of the working section, including the mission statement and a photo of the team.
 * @returns The working section of the landing page.
 */
export default function ProjectDescriptionWorkingSection({aboutSubtext, aboutBgPhoto, captionTitle, captionSubtext}) {

	return (

		<section className="pb-20 bg-gray-100 -mt-32">
			<div className="container max-w-7xl mx-auto px-4">
				<div className="flex flex-wrap relative z-10">

					<StatusCard
						color="lightBlue"
						icon={User}
						title="About"
						nav="AboutUs"
						delay={0}
					>
					</StatusCard>

					<StatusCard
						color="lightBlue"
						icon={CodeXml}
						title="Development"
						nav="TechWeUse"
						delay={100}
					>
					</StatusCard>

					<StatusCard
						color="lightBlue"
						icon={PieChart}
						title="Our Impact"
						nav="OurImpact"
						delay={200}
					>
					</StatusCard>
				</div>

				<div className="AboutUs"></div>

				<div className="flex flex-wrap items-center mt-32">
					<div className="w-full md:w-5/12 px-4 mx-auto">
						<div
							data-aos="fade"
							className=
								"text-blue-gray-800 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white"
						>
							<Icon name="people" size="3xl" />
						</div>
						<H4 data-aos="fade" color="gray">About</H4>
						<LeadText data-aos="fade" color="blueGray">
                            {aboutSubtext}
						</LeadText>
					</div>

					<div data-aos="fade" className="w-full md:w-4/12 px-4 mx-auto flex justify-center mt-24 lg:mt-0">
						<Card>
							<CardImage
								alt="Project description photo."
								src={aboutBgPhoto}
							/>
							<CardBody>
								<H6 color="gray">{captionTitle}</H6>
								<Paragraph color="blueGray">
								{captionSubtext}
								</Paragraph>
							</CardBody>
						</Card>
					</div>

				</div>
			</div>
		</section>
	);
}
