import H5 from '@material-tailwind/react/Heading5';
import Icon from '@material-tailwind/react/Icon';
import { scrollToSection } from 'components/scrollToSection';
import Button from '@material-tailwind/react/Button';
import 'aos/dist/aos.css';
import React from 'react';
import { Link } from 'react-router-dom';

/**
 * The default footer for the landing page
 * @returns The default footer for the landing page.
 */
export default function DefaultFooter() {

	return (
		<>
			<footer className='relative bg-gray-100 pt-8 pb-6'>
				<div className='container max-w-7xl mx-auto px-4'>
					<div className='flex flex-wrap text-center xl:text-left md:text-center sm:text-center lg:text-left pt-6'>
						<div data-aos='fade' className='w-full items-center lg:w-6/12 px-4'>
							<H5 color='gray'>Zenith Software</H5>
							<div className='flex flex-wrap justify-center lg:justify-start items-center space-x-2 mt-4 mb-4 lg:mb-0 lg:-ml-3'>
								<a
									rel='noopener noreferrer'
									href='https://www.linkedin.com/company/zenith-software-development/'
									target='_blank'
								>
									<Button
										color='blue'
										buttonType='link'
										ripple='dark'
										rounded
										iconOnly
									>
										<Icon
											family='font-awesome'
											name='fab fa-linkedin'
											size='lg'
										/>
									</Button>
								</a>
								<a
									rel='noopener noreferrer'
									href='https://www.instagram.com/zenithsoftwaredev/'
									target='_blank'
								>
									<Button
										color='pink'
										buttonType='link'
										ripple='dark'
										rounded
										iconOnly
									>
										<Icon
											family='font-awesome'
											name='fab fa-instagram'
											size='lg'
										/>
									</Button>
								</a>
								<a
									rel='noopener noreferrer'
									href='https://www.facebook.com/Zenith-Software-100289855796121'
									target='_blank'
								>
									<Button
										color='blue'
										buttonType='link'
										ripple='dark'
										rounded
										iconOnly
									>
										<Icon
											family='font-awesome'
											name='fab fa-facebook-f'
											size='lg'
										/>
									</Button>
								</a>
							</div>
						</div>
						<div className='w-full lg:w-6/12 px-4'>
							<div className='flex flex-wrap items-top'>
								<div data-aos='fade' className='w-full lg:w-4/12 px-4 ml-auto md:mb-0 mb-8'>
									<span className='block uppercase text-gray-900 text-sm font-serif font-medium mb-2'>
                                        Other Resources
									</span>
									<ul className='list-unstyled'>
										<li>
											<Link
												className='text-gray-700 hover:text-gray-900 block pb-2 text-sm'
												to='/about'
												onClick={() => {
													return scrollToSection('logo', { duration: 2000 });
												}}
											>
                                                About Us
											</Link>
										</li>
										<li>
											<Link
												className='text-gray-700 hover:text-gray-900 block pb-2 text-sm'
												to='/'
												onClick={() => {
													return scrollToSection('ContactSection', { duration: 2000 });
												}}
											>
                                                Contact Us
											</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<hr className='my-6 border-gray-300' />
					<div className='flex flex-wrap items-center md:justify-between justify-center'>
						<div className='w-full md:w-4/12 px-4 mx-auto text-center'>
							<div className='text-sm text-gray-700 font-medium py-1'>
                                Copyright © {new Date().getFullYear()} Zenith Software
							</div>
						</div>
					</div>
				</div>
			</footer>
		</>
	);
}
