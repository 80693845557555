import React from "react";
import ServiceCard from "components/about/ServiceCard";
import { BriefcaseBusiness, CodeXml, Monitor, Palette, PanelsTopLeft, TabletSmartphone } from "lucide-react";
import { DiApple, DiAndroid } from "react-icons/di";
import { useIsMobile } from "components/isMobile";
import TitleBlurb from "components/TitleBlurb";

const webServices = [
	{
		Icon: BriefcaseBusiness,
		title: "Enterprise Applications",
		description: "Robust, scalable solutions for large-scale business operations and complex workflows."
	},
	{
		Icon: CodeXml,
		title: "Custom Websites",
		description: "Fast, responsive websites that exceed your vision, custom-tailored to your needs."
	},
	{
		Icon: Monitor,
		title: "No-Code Web Solutions",
		description: "Affordable, user-friendly platforms to help small businesses thrive in the digital marketplace."
	},
	{
		Icon: PanelsTopLeft,
		title: "Website Mockups",
		description: "High-fidelity website mockups that bring your vision to life before development even begins."
	},
];

const mobileServices = [
	{
		Icon: DiApple,
		title: "iOS",
		description: "Sleek, powerful applications designed specifically for Apple's ecosystem.",
		strokeWidth: 0,
	},
	{
		Icon: DiAndroid,
		title: "Android",
		description: "Feature-rich apps optimized for the diverse range of Android devices.",
		strokeWidth: 0,
	},
	{
		Icon: TabletSmartphone,
		title: "Cross-Platform",
		description: "Cost-effective solutions that work seamlessly on both iOS and Android from a single codebase."
	},
	{
		Icon: Palette,
		title: "Mobile App Mockups",
		description: "Detailed prototypes to visualize and refine the user experience, setting a clear direction for development."
	},
];

function CardRow({ services, title }) {
	const isMobile = useIsMobile();
	const transitionSpeed = 150;
	return (
		<div className="flex flex-col px-12 w-full items-center justify-self-center">
			<h2 data-aos="fade" className="w-full text-2xl font-sans text-gray-800 font-normal text-left py-6">{title}</h2>
			<div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-8">
				{services.map((service, index) => (
					<ServiceCard
						key={index} 
						delay={((isMobile ? 1 : index) * transitionSpeed).toString()}
						aosType={isMobile ? (index % 2 === 0 ? "fade-left" : "fade-right") : "fade-up"}
						Icon={service.Icon}
						strokeWidth={service.strokeWidth}
						title={service.title}
						description={service.description}
					/>
				))}
			</div>
		</div>
	);
}

export default function OurServices() {
	return (
		<div className="OurServices bg-gray-100 w-screen flex justify-center">
			<div className="py-20 max-w-screen-2xl">
				<TitleBlurb
					title="Our Services"
					// eslint-disable-next-line max-len
					blurb="We offer a comprehensive range of software development services to meet the needs of businesses both large and small."
				/>
				<CardRow services={webServices} title="Web Development" />
				{/* 10 px spacer */}
				<div className="h-10"></div>
				<CardRow services={mobileServices} title="Mobile Development" />
			</div>
		</div>
	);
}