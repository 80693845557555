import ProjectCard from "components/projects/ProjectCard";
import projectData from "./ProjectData";
import YourProjectHere from "components/YourProjectHere";
import TitleBlurb from "components/TitleBlurb";

export default function ProjectSection() {
	return (
		<div className="mx-auto flex flex-col items-center justify-center h-100 py-20 px-5">

			<TitleBlurb
				title="Our Portfolio"
				// eslint-disable-next-line max-len
				blurb="Excellence in action. Check out our diverse range of projects that showcase our expertise and innovation. Mobile apps, websites, projects big and small - we've got you covered."
			/>

			<div className="h-auto flex flex-col items-center justify-center container w-full gap-8 md:gap-20">
				{projectData.map((project, index) => (
					<ProjectCard
						key={index}
						title={project.title}
						description={project.description}
						buttonColor={project.buttonColor}
						buttonLink={project.buttonLink}
						imageSrc={project.imageSrc}
						inverted={index % 2 === 0}
					/>
				))}
			</div>

			<div
				className="w-full mx-auto py-8 md:py-24"
				data-aos="fade-up"
				data-aos-delay="200"
				style={{
					textAlign: "center",
				}}
			>
				<YourProjectHere />
			</div>
		</div>
	);
}