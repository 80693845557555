import "aos/dist/aos.css";
import React from "react";



export default function OurImpactCard({ imageSrc, altText, title, description, variant }) {

    const direction = variant === "left" ? "md:flex-row-reverse" : "";
    const desktopStyles = `
        flex
        flex-row
        ${direction}
        flex-wrap
        md:flex-nowrap
        items-stretch
        gap-8
        md:gap-16
        lg:gap-32
        items-center
        bg-white
        shadow-md
        rounded-xl
        w-full
        md:px-16 md:py-6
        p-4
        `

    const width = "w-full";

    return (
        <div className={desktopStyles} data-aos={variant === "left" ? "fade-left" : "fade-right"}>
            <img
                src={imageSrc}
                alt={altText}
                className={`${width} max-h-80 rounded-xl object-cover`}
            />
            <div className={`${width}`}>
                <h6 className="text-gray-900 text-xl font-serif font-bold leading-normal mt-0 mb-2 ">{title}</h6>
                <p className="text-blue-gray-700 leading-loose">{description}</p>
            </div>
        </div>
    );
}