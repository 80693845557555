import { useState, useEffect } from 'react';

/**
 *
 *A small hook to determine if the current window is mobile size.
 * @returns True if screen width is less than `mobileScreenThresholdPx`; False otherwise
 */
export function useIsMobile() {

	const mobileScreenThresholdPx = 768;

	const [ isMobile, setIsMobile ] = useState(window.innerWidth < mobileScreenThresholdPx);

	useEffect(() => {

		/**
		 * Handles the window resize event and updates the isMobile state.
		 * @returns This function does not return a value.
		 */
		function handleResize() {
			return setIsMobile(window.innerWidth < 768);
		}
		window.addEventListener('resize', handleResize);
		return () => {
			return window.removeEventListener('resize', handleResize);
		};
	}, []);

	return isMobile;
}
