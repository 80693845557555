import React from 'react';
import TeamCard from 'components/landing/TeamCard';
import 'assets/styles/teamSection.css';
import 'aos/dist/aos.css';

import { teamMembers } from './teamData';
import { useIsMobile } from 'components/isMobile';
import TitleBlurb from 'components/TitleBlurb';

/**
 * The Team Section component for the landing page.
 * @returns The team section component of the landing page.
 */
export default function TeamSection() {
	const isMobile = useIsMobile();

	/**
	 * Renders a team member card.
	 * @param member - The team member object containing details.
	 * @param index - The index of the team member in the list.
	 * @returns The JSX element for the team member card.
	 */
	function renderTeamMember(member, index) {
		return <TeamCard key={member.name} layout={index % 2 === 0 ? 'left' : 'right'} {...member} />;
	}

	return (
		<section id='team-section' className='pt-20 pb-48'>
			<div className='TeamSection'></div>
			<div data-aos='fade' className={`${isMobile ? 'container' : ''} max-w-7xl mx-auto px-4`}>
				<TitleBlurb
					title='Meet the Team'
					// eslint-disable-next-line max-len
					blurb='Our team has experience in a wide variety of software engineering projects. Be sure to check out our LinkedIn, GitHub, and Instagram profiles to connect with us!'
				/>
				<div className={isMobile ? 'flex flex-wrap' : ''}>
					{teamMembers.map(renderTeamMember)}
				</div>
			</div>
		</section>
	);
}
