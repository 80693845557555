import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button as MTButton } from "@material-tailwind/react";

/**
 * Custom Button component wrapping Material Tailwind Button
 * @param {ReactNode} children - Button content
 * @param {string} backgroundColor - Custom background color
 * @param {string} textColor - Custom text color
 * @param {string} size - Button size (default: "lg")
 * @param {boolean} fullwidth - Whether the button should take full width
 * @param {string} className - Additional CSS classes
 * @param {Object} props - Additional props to be spread on the button
 */
function ViewOurWorkButton({ 
	children = "View Our Work",
	backgroundColor = "white", 
	textColor = "#03a9f4", 	// default to zenith color
	size = "lg", 
	fullwidth = "false", 
	className = "",
	link = "/",
	...props 
}) {
	const buttonStyle = {
		backgroundColor,
		color: textColor,
	};

	const navigate = useNavigate();
	const handleClick = () => {
		return navigate(link);
	};
	

	return (
		<MTButton
			size={size}
			fullwidth={fullwidth}
			onClick={handleClick}
			className={`
				uppercase 
				font-bold 
				bg-white 
				hover:bg-blue-50 
				px-6 
				py-3 
				rounded-md 
				transition-all 
				duration-300
				${className}
			`}
			style={buttonStyle}
			{...props}
		>
			{children}
		</MTButton>
	);
}

export default ViewOurWorkButton;
