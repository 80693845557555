import {
  Dio,
  DioDescription,
  DioImpact1,
  DioImpact2,
  DioImpact3,
  Cantaloupe,
  CantaloupeDescription,
  CantaloupeImpact1,
  CantaloupeImpact2,
  Earshot,
  EarshotDescription,
  EarshotImpact1,
  EarshotImpact2,
  EarshotImpact3,
  EarshotPhone,
  // Skillmasters,
  // SkillmastersDescription,
  // SkillmastersImpact1,
  // SkillmastersImpact2,
  // SkillmastersImpact3,
  // SkillmastersImpact4,
  Aura,
  AuraDescription,
  AuraImpact1,
  AuraImpact2,
  AuraImpact3,
  // OnFire,
  // OnFireDescription,
} from "./ProjectImages";

const projectData = [
    {
      title: "Dio",
      description: "Order and pay for your favorite cocktails, beers, or wines before you arrive at the bar. Walk in, grab your drink, and enjoy your night without the wait.",
      aboutInfo: "Dio is a mobile app that gives bar lovers the ability to order drinks ahead of time to reduce time spent in lines. The project is currently entering early beta stages in New York & New Orleans.",
      bgPic: "dio-project-bg",
      aboutBgPhoto: DioDescription,
      captionTitle: "A Better Way to Party",
      captionSubtext: "Ditch the lines on your night out. Order your drinks ahead with the Dio app to skip the wait and maximize your fun!",
      appStoreText: "Get it on the",
      appStoreText2: "App Store today!",
      appStoreButtonText: "View Our Work",
      appStoreLink: "",
      technologies: 	"Expo, React Native, Dynamo DB, AWS Cognito, Figma, TypeScript, Stripe",
      buttonColor: "#1F677C",
      buttonLink: "/projectdescription?projectName=dio",
      stepCardInfo: [{
        title: "1.  Designs",
        description: "We leveraged Figma to collaborate on intuitive designs for Dio's user interface. Through iterative feedback sessions with the client, we refined the UI/UX to ensure it aligned perfectly with their vision and user expectations.",
        altText: "Dio Figma Designs",
        imageSrc: DioImpact1
      },
      {
        title: "2.  System Architecture",
        description: "To power Dio's backend, we implemented a robust solution using DynamoDB for efficient data storage and retrieval. We integrated AWS Cognito to handle user authentication and authorization, ensuring a secure and scalable foundation for the application.",
        altText: "AWS Logo",
        imageSrc: DioImpact2
      },
      {
        title: "3.  MVP Mindfulness",
        description: "Throughout the development process, we maintained a laser focus on delivering an MVP that met core user needs. By prioritizing essential features and functionalities, we were able to launch a streamlined version of Dio that allowed for future iterations and enhancements.",
        altText: "The Boot Bar",
        imageSrc: DioImpact3
      }],
      imageSrc: Dio,
      inAppStore: false
    },
    {
      title: "Cantaloupe",
      description: "Cantaloupe goes beyond traditional screening methods, assessing social fit, long-term potential, and specific job requirements to deliver a shortlist of top-tier candidates.",
      aboutInfo: "Cantaloupe is a tool for businesses looking to hire employees. It uses a custom-tailored AI to filter & showcase the highest-rated candidates based on your requirements, reducing turnover.",
      bgPic: "cantaloupe-project-bg",
      aboutBgPhoto: CantaloupeDescription,
      captionTitle: "Smarter Hiring for Less Turnover",
      captionSubtext: "Empower businesses with Cantaloupe's web-based business portal for efficient candidate management and communication.",
      appStoreText: "Get it on the",
      appStoreText2: "App Store today!",
      appStoreButtonText: "View Our Work",
      appStoreLink: "",
      technologies: 	"React Native, AWS, DynamoDB, TypeScript, Stream Chat, Expo",
      buttonColor: "#30513F",
      buttonLink: "/projectdescription?projectName=cantaloupe",
      stepCardInfo: [{
        title: "1.  Real-Time Communication",
        description: "By implementing Stream Chat, we enhanced the portal with real-time messaging capabilities. This feature facilitates seamless communication between hiring managers and candidates, streamlining the interview process.",
        altText: "dio image",
        imageSrc: CantaloupeImpact1
      },
      {
        title: "2.  Agile Development",
        description: "Our team demonstrated our flexibility by swiftly adapting our development strategy. We leveraged our knowledge in React Native to transition smoothly to a web-based environment, ensuring a quick turnaround without compromising on functionality.",
        altText: "dio image",
        imageSrc: CantaloupeImpact2
      }],
      imageSrc: Cantaloupe,
      inAppStore: false
    },
    {
      title: "Earshot",
      description: "Earshot revolutionizes social interaction by allowing users to anonymously join location-based chatrooms across the country.",
      aboutInfo: "Earshot, being Zenith's first in-house project, was created as a senior class project at LSU. It was the group's first experience working on a mobile app. You can find it on the App Store today!",
      bgPic: "earshot-project-bg",
      aboutBgPhoto: EarshotDescription,
      captionTitle: "Connect Anonymously, Locally",
      captionSubtext: "Experience the pulse of your surroundings through Earshot's innovative location-based chatrooms.",
      appStoreText: "Get it on the",
      appStoreText2: "App Store today!",
      appStoreButtonText: "Download the App",
      appStoreLink: "https://apps.apple.com/us/app/earshot-proximity-chat/id6471038992",
      appStorePhonePic: EarshotPhone,
      technologies: 	"React Native, DynamoDB, AWS Cognito, Figma, App Store Deployment",
      buttonColor: "#8F7BFA",
      buttonLink: "/projectdescription?projectName=earshot",
      stepCardInfo: [{
        title: "1.  Project Conceptualization",
        description: "As our first in-house project, we conceptualized and brought to life a unique social platform that bridges the gap between digital interaction and physical proximity, fostering anonymous yet localized connections.",
        altText: "dio image",
        imageSrc: EarshotImpact1
      },
      {
        title: "2.  Academic to Real-World Transition",
        description: "Earshot represents our successful transition from academic projects to real-world application development. The app's availability on the App Store stands as a testament to our ability to deliver market-ready products.",
        altText: "dio image",
        imageSrc: EarshotImpact2
      },
      {
        title: "3.  Future-Ready Planning",
        description: "While focusing on delivering a functional MVP, we also planned for future enhancements, including the integration of Google Maps API for seamless and infinite chatroom creation in Phase 2.",
        altText: "dio image",
        imageSrc: EarshotImpact3
      }],
      imageSrc: Earshot,
      inAppStore: true

    },
    // {
    //   title: "Skillmasters Cognitive Testing Portal",
    //   description: "Skillmasters is a comprehensive suite of cognitive tests designed specifically for youth sports programs.",
    //   aboutInfo: "Zenith was tasked with creating designs for a list of cognitive tests and implementing them into a functional platform. The project aims to assess and enhance cognitive skills crucial for young athletes.",
    //   bgPic: "skillmasters-project-bg",
    //   aboutBgPhoto: SkillmastersDescription,
    //   captionTitle: "Empowering Young Athletes' Minds",
    //   captionSubtext: "Revolutionizing cognitive assessment in youth sports through interactive, digital testing.",
    //   appStoreText: "Get it on the",
    //   appStoreText2: "App Store today!",
    //   appStoreButtonText: "View Our Work",
    //   technologies: 	"TypeScript, Chakra UI, p5.js, DynamoDB, AWS Serverless, AWS Cognito, Figma",
    //   buttonColor: "#F5881F",
    //   buttonLink: "/projectdescription?projectName=skillmasters cognitive testing portal",
    //   stepCardInfo: [{
    //     title: "1.  Innovative Test Design",
    //     description: "We designed and implemented a series of interactive cognitive tests tailored specifically for young athletes. These tests assess crucial skills relevant to sports performance, providing valuable insights for coaches and players alike.",
    //     altText: "dio image",
    //     imageSrc: SkillmastersImpact1
    //   },
    //   {
    //     title: "2.  User-Friendly Interface",
    //     description: "Utilizing Chakra UI and p5.js, we created an engaging and intuitive interface that makes cognitive testing accessible and enjoyable for young users. The interactive elements ensure high engagement and accurate results.",
    //     altText: "dio image",
    //     imageSrc: SkillmastersImpact2
    //   },
    //   {
    //     title: "3.  Scalable Architecture",
    //     description: "Our implementation of DynamoDB, AWS Serverless, and AWS Cognito resulted in a robust backend capable of handling thousands of tests monthly. This scalable architecture ensures reliable performance as the platform continues to grow in popularity.",
    //     altText: "dio image",
    //     imageSrc: SkillmastersImpact3
    //   },
    //   {
    //     title: "4.  Data-Driven Development",
    //     description: "By providing cognitive assessments to youth soccer programs nationwide, Skillmasters is contributing to a more comprehensive approach to athlete development. The data gathered helps coaches tailor training programs and identify areas for improvement.",
    //     altText: "dio image",
    //     imageSrc: SkillmastersImpact4
    //   },],
    //   imageSrc: Skillmasters
    // },
    {
      title: "Aura",
      description: "Aura is a desktop dashboard designed to provide real-time data analytics for cartop advertisements.",
      aboutInfo: "Aura revolutionizes outdoor advertising by offering clients detailed insights into their cartop billboard campaigns. The platform tracks and displays where ads have played and provides real-time impression data, allowing for more effective ad placement and performance analysis.",
      bgPic: "aura-project-bg",
      aboutBgPhoto: AuraDescription,
      captionTitle: "Analyzing Mobile Ads",
      captionSubtext: "Aura's powerful dashboard brings real-time insights to cartop advertising campaigns, transforming data into actionable strategies.",
      appStoreText: "Get it on the",
      appStoreText2: "App Store today!",
      appStoreButtonText: "View Our Work",
      appStoreLink: "",
      technologies: 	"Chakra UI, TypeScript, AWS Cognito, AWS Serverless, DynamoDB, Figma",
      buttonColor: "#ABA5E8",
      buttonLink: "/projectdescription?projectName=aura",
      stepCardInfo: [{
        title: "1.  User-Centric Design",
        description: "Leveraging Figma, Chakra UI, and TypeScript, we created an intuitive and responsive interface that allows clients to easily navigate complex data sets. The dashboard presents information in a clear, visually appealing manner, enhancing decision-making processes.",
        altText: "dio image",
        imageSrc: AuraImpact1
      },
      {
        title: "2.  Data-Driven Decisions",
        description: "By providing comprehensive, real-time analytics, Aura empowers clients to make data-driven decisions about their advertising strategies. This approach leads to more effective campaigns and better ROI for outdoor advertising investments.",
        altText: "dio image",
        imageSrc: AuraImpact2
      },
      {
        title: "3.  Scalability for Future Growth",
        description: "The platform's architecture is designed to accommodate future growth and additional features. This forward-thinking approach ensures that Aura can evolve with market demands and maintain its competitive edge.",
        altText: "dio image",
        imageSrc: AuraImpact3
      }],
      imageSrc: Aura,
      inAppStore: false
    },
    // {
    //   title: "OnFire Athletes",
    //   description: "OnFire Athletes allows users to design, create, and customize their own athlete trading cards. Users can trade and collect their favorite cards through the digital marketplace.",
    //   aboutInfo: "The platform allows sports enthusiasts to design their own unique digital sports cards and participate in a dynamic marketplace. Users can trade their creations for other cards or sell them for monetary value.",
    //   bgPic: "onfire-project-bg",
    //   aboutBgPhoto: OnFireDescription,
    //   captionTitle: "Igniting the Digital Sports Card Revolution",
    //   captionSubtext: "Where fans become creators in a vibrant marketplace of customized digital sports cards.",
    //   appStoreText: "Get it on the",
    //   appStoreText2: "App Store today!",
    //   appStoreButtonText: "View Our Work",
    //   technologies: 	"Chakra UI, TypeScript, AWS Cognito, AWS Serverless, DynamoDB, Figma, AR",
    //   buttonColor: "#27CE00",
    //   buttonLink: "/projectdescription?projectName=onfire athletes",
    //   stepCardInfo: [{
    //     title: "1.  Empowering Sports Fans",
    //     description: "By allowing users to create and customize their own athlete trading cards, we've transformed passive fans into active participants in the sports memorabilia ecosystem. This empowerment creates a deeper connection between fans and their favorite sports.",
    //     altText: "dio image",
    //     imageSrc: OnFire
    //   },
    //   {
    //     title: "2.  Scalability for Future Growth",
    //     description: "The platform's architecture is designed to accommodate future growth and additional features. This forward-thinking approach ensures that OnFire Athletes can evolve with user demands and expand its offerings.",
    //     altText: "dio image",
    //     imageSrc: OnFire
    //   },
    //   {
    //     title: "3.  Comprehensive Project Development",
    //     description: "Zenith's involvement in every aspect of the project, from conception to implementation, demonstrates our ability to deliver end-to-end solutions. Our dedicated approach has been crucial in bringing OnFire Athletes to the beta testing phase.",
    //     altText: "dio image",
    //     imageSrc: OnFire
    //   }],
    //   imageSrc: OnFire
    // }
  ];
  
  export default projectData;
  