import React from 'react';
import Button from '@material-tailwind/react/Button';
import Icon from '@material-tailwind/react/Icon';
import 'aos/dist/aos.css';
import { useIsMobile } from 'components/isMobile';

/**
 * The scroll to top button for the landing page.
 * @returns The button to scroll to the top of the page.
 */
export default function ScrollTopButton() {
	const isMobile = useIsMobile();

	/**
	 * Scrolls the window to the top smoothly.
	 */
	function scrollToTop() {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	}

	const mobileStyle = {
		height: '20px',
		zIndex: 19,
		bottom: '30px',
		left: 'calc(100vw - 50px)',
	};

	const desktopStyle = {
		height: '20px',
		zIndex: 100,
		bottom: '36px',
		left: '94%'
	};

	return (
		// Doing style tag for exact pixel values and z-index
		<div
			className={'fixed w-full text-5xl cursor-pointer'}
			data-aos='fade'
			style={isMobile ? mobileStyle : desktopStyle}
		>
			<Button
				color='lightBlue'
				buttonType='filled'
				size='regular'
				rounded={true}
				block={false}
				iconOnly={true}
				ripple='light'
				onClick={scrollToTop}
			>
				<Icon name='arrow_upward' size='xlg' />
			</Button>
		</div>

	);
}
