import { scroller } from 'react-scroll';

/**
 * Scrolls to a specific section on the page.
 * @param navClass - The class name of the navigation element to scroll to.
 * @param [duration] - The duration of the scroll animation in milliseconds.
 */
export function scrollToSection(navClass, duration = 800) {
	scroller.scrollTo(navClass, {
		duration: duration,
		delay: 0,
		smooth: 'easeInOutQuart',
	});
}
