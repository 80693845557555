import H3 from "@material-tailwind/react/Heading3";
import LeadText from "@material-tailwind/react/LeadText";

export default function TitleBlurb({ title, blurb }) {
	return (
		<div className="flex justify-center items-center text-center mb-24">
			<div className="w-full lg:w-1/2 px-4">
				<H3 color="gray">{title}</H3>
				<LeadText color="blueGray">{blurb}</LeadText>
			</div>
		</div>
	);
}