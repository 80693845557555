import BlakeImg from 'assets/img/Blake800x800.png';
import DarrinImg from 'assets/img/Darrin800x800.png';
import JohnImg from 'assets/img/John800x800.png';
import TrevorImg from 'assets/img/Trev800x800.png';
import TreImg from 'assets/img/Tre800x800.jpg';
import CalvinImg from 'assets/img/Calvin800x800.png';
import MiguelImg from 'assets/img/Miguel800x800.jpg';

export const teamMembers = [
	{
		img: DarrinImg,
		name: 'Darrin Lea',
		position: 'CEO, Co-Founder',
		linkedIn: 'https://www.linkedin.com/in/darrin-lea/',
		gitHub: 'https://github.com/dlea8',
		instagram: 'https://www.instagram.com/darrinlea8/',
		bio: 'After earning undergraduate degrees in math and computer science at LSU, ' +
			'Darrin returned to pursue his masters in cybersecurity. Throughout his ' +
			'undergraduate years, Darrin developed applications for startups, fortune 500 ' +
			'companies, and the US government and worked to secure the nation\'s critical ' +
			'infrastructure with the Department of Energy\'s National Labs. At Zenith, he ' +
			'works alongside a renowned team of developers on a variety of full-stack web ' +
			'applications and cross-platform mobile apps for clients across the country.',
	},
	{
		img: BlakeImg,
		name: 'Blake Bollinger',
		position: 'CTO, Co-Founder',
		linkedIn: 'https://www.linkedin.com/in/blake-bollinger/',
		gitHub: 'https://github.com/blakebollinger',
		instagram: 'https://www.instagram.com/blakelbollinger/',
		bio: 'Blake is a recent LSU graduate with a dual major in Computer Science and ' +
			'Mathematics. He has a passion for mobile app development and creating products ' +
			'people love. He has worked on a wide variety of projects including Apple ' +
			'award-winning machine learning applications, native iOS and Android apps for ' +
			'Fortune 500 companies, and cutting-edge augmented reality research. He brings ' +
			'his passion to Zenith Software to help take the company\'s software development ' +
			'to the next level.',
	},
	{
		img: JohnImg,
		name: 'John Bajor',
		position: 'COO, Co-Founder',
		linkedIn: 'https://www.linkedin.com/in/johnbajor/',
		gitHub: 'https://github.com/jjbajor',
		instagram: 'https://www.instagram.com/jjbajor/',
		bio: 'John, a Computer Science graduate from LSU, has been in love with the ' +
			'creative side of technology for his entire life. From experimenting with ' +
			'modding his old game systems as a kid to building his first PC by hand in ' +
			'high school, he has always been learning new and interesting ways to ' +
			'personalize his experience with technology. Having earned a Minor in Digital ' +
			'Media & Technology through his tenure at LSU, John enjoys focusing on the User ' +
			'Experience of Zenith\'s applications, and tailoring that experience to deliver ' +
			'a better product to clients.',
	},
	{
		img: TrevorImg,
		name: 'Trevor Baughman',
		position: 'Software Engineer',
		gitHub: 'https://github.com/trevbaugh',
		instagram: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
		bio: 'Trevor has graduated from LSU with a Computer Science degree. Throughout ' +
			'his time in college he has been interested in technology. During his time ' +
			'there, he improved his skills by developing scripts and websites using Python ' +
			'and TypeScript. He has been helping Zenith with his experience in frontend and ' +
			'backend development. He thoroughly enjoys working on projects with Zenith as a ' +
			'full stack developer. Trevor is very excited to help Zenith grow while working ' +
			'with the company.',
	},
	{
		img: TreImg,
		name: 'Tre Landaiche',
		position: 'Software Engineer',
		linkedIn: 'https://www.linkedin.com/in/tre-landaiche/',
		gitHub: 'https://github.com/ThatGuyTre',
		instagram: '',
		bio: 'Tre Landaiche is a Cybersecurity graduate student with a knack for creating ' +
			'good-looking software. Since joining the crew in March of \'22, he has gained ' +
			'experience with Web Development using React frameworks alongside TypeScript. ' +
			'Java, C, and Python are also in his toolbelt and used for smaller side ' +
			'projects. Recently, Tre has gotten into backend automation and performance ' +
			'analytics across his projects. He hopes to continue working with clients ' +
			'alongside his friends at Zenith as long as time allows.',
	},
	{
		img: CalvinImg,
		name: 'Calvin W. Feldt',
		position: 'Software Engineer',
		linkedIn: 'https://www.linkedin.com/in/calvin-feldt-9a8988235/',
		gitHub: 'https://github.com/C-Feldt',
		instagram: 'https://www.instagram.com/feldthat?igsh=MXFwYzEzNHVqbWd3ZA==',
		bio: 'Calvin is a Ph.D. candidate in Computer Science at LSU, specializing in ' +
			'Human-Computer Interaction. His research explores the practicality of using ' +
			'augmented reality as a tool to enhance the workday experience. At Zenith, ' +
			'Calvin works as a full-stack developer, where he brings a strong passion for ' +
			'front-end design. He has played a critical role in the development of numerous ' +
			'cross-platform mobile and web applications, continuously seeking innovative ' +
			'solutions to improve user experience and drive project success.',
	},
	{
		img: MiguelImg,
		name: 'Miguel Seruntine',
		position: 'Software Engineer',
		linkedIn: 'https://www.linkedin.com/in/miguel-s-54980a137/',
		gitHub: 'https://github.com/mseruntine',
		instagram: 'https://www.instagram.com/miguel_shmerpentine?igsh=eHY3eDAydWtmbWk5',
		bio: 'Having graduated from LSU in Computer Science, with a concentration in ' +
			'software engineering, Miguel is armed and dangerous with the knowledge needed ' +
			'to build quality software. Miguel enjoys working on frontend implementation ' +
			'and design, with his favorite part of working at Zenith being the privilege of ' +
			'working with Zenith Software\'s talented and dynamic team of programmers! And ' +
			'when he isn\'t programming, you can find Miguel performing music on the streets ' +
			'of New Orleans throughout the week!',
	}
];
