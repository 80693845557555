import React from "react";
import "aos/dist/aos.css";
import "assets/styles/ProcessCard.css";


/**
 *
 * @param {*} param0
 * @returns
 */
export default function ProcessCard({ img, title, description, layout }) {
	const imageRight = layout === "right";
	const flexReverse = imageRight ? "md:flex-row-reverse" : "";

	const containerStyles  = `
	flex
	flex-row
	${flexReverse}
	flex-wrap
	md:flex-nowrap
	items-stretch
	gap-8
	md:gap-16
	lg:gap-32
	items-center
	w-full

	`

	return (
		<div
			data-aos={layout === "left" ? "fade-right" : "fade-left"}
			className={containerStyles}
		>
			{/* Image Section */}
			<div className={`w-full md:w-1/2`}>
				<img
					src={img}
					alt={""}
					className={"max-h-80 w-full rounded-xl object-cover"}
				/>
			</div>

			{/* Text Section */}
			<div className={`flex flex-col h-full w-full md:w-1/2 `}>
				<div>
					<div className="mb-4">

						{/* Title */}
						<p
							className="text-2xl font-semibold font-serif pb-4"
							data-aos="fade-up"
							data-aos-delay="100"
						>
							{title}
						</p>

						{/* Description */}
						<p
							className="text-xl md:text-2xl font-light leading-relaxed"
							data-aos="fade-up"
							data-aos-delay="200"
						>
							{description}
						</p>
					</div>
				</div>
			</div>
			

		</div>
	);
}
