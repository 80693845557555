import DefaultNavbar from 'components/DefaultNavbar';
import DefaultFooter from 'components/DefaultFooter';
import ScrollTopButton from 'components/landing/ScrollTopButton';
import 'assets/styles/entirePage.css';
import ProjectSection from 'components/projects/ProjectSection';
import HeaderSection from 'components/HeaderSection';

/**
 * The about us page of the website.
 * @returns The about us page with its components.
 */
export default function Projects() {
	return (
		<div className='hideOverflow'>
			<div className='absolute w-full z-20'>
				<DefaultNavbar />
			</div>
			<main>
				<HeaderSection
					backgroundClass='projects-background'
					pageTitle='Become Our Next Satisfied Customer Today!'
					// eslint-disable-next-line max-len
					subtext={'From scalable web applications to robust mobile platforms, each finished project reflects our commitment to excellence, innovation, and client success.\n\nExplore our featured works below and discover how we can bring your vision to life.'}
				/>
				<ProjectSection />
				<ScrollTopButton />
			</main>
			<DefaultFooter />
		</div>
	);
}
