import React from "react";
import PropTypes from "prop-types";
import { X } from "lucide-react";

const NavigationDrawer = ({ isOpen, onClose, buttonGroups }) => {
	const gapFromEdge = "30px";

	return (
		<div
			className={`fixed top-0 left-0 h-full bg-white text-black shadow-lg transform transition-all duration-300 ease-in-out z-40`}
			style={{
				width: `calc(100vw - ${gapFromEdge})`,
				transform: isOpen ? "translateX(100vw)" : `translateX(${gapFromEdge})`
			}}
		>
			<div className="flex flex-col h-full">
				{/* Just enough to let you press the same spot as the open button */}
				<div className="py-4 px-6 flex h-20 justify-between items-center bg-white">
					<button
						onClick={onClose}
						className="text-zenith text-4xl ml-auto h-full"
					>
						<X size={44}/>
					</button>
				</div>
				<div className="flex-1 overflow-y-auto px-8 py-4 space-y-4">
					{buttonGroups.map((group, index) => (
						<div key={index}>
							<h2 className="text-xl font-serif text-gray-700 mb-4">{group.title}</h2>
								<div className="space-y-2">
									{group.buttons.map((button, btnIndex) => (
										<button
											key={btnIndex}
											onClick={() => {
												onClose();
												return button.onClick();
											}}
											className="flex items-center space-x-2 p-2 w-full text-left text-lg rounded"
										>
											<button.icon className="w-14 h-6" />
											<span>{button.label}</span>
										</button>
									))}
							</div>
						</div>
					))}
				</div>
				<p className="text-xs text-center font-sans text-zenith mb-4">
					© Zenith Software {new Date().getFullYear()}. All Rights Reserved
				</p>
			</div>
		</div>
	);
};

NavigationDrawer.propTypes = {
isOpen: PropTypes.bool.isRequired,
onClose: PropTypes.func.isRequired,
buttonGroups: PropTypes.arrayOf(
	PropTypes.shape({
		title: PropTypes.string.isRequired,
		buttons: PropTypes.arrayOf(
			PropTypes.shape({
				label: PropTypes.string.isRequired,
				onClick: PropTypes.func.isRequired,
				icon: PropTypes.elementType.isRequired,
			})
		).isRequired,
	})
).isRequired,
};

export default NavigationDrawer;
