import LeadText from '@material-tailwind/react/LeadText';
import 'aos/dist/aos.css';
import React from 'react';
import { FaJava,
	FaPython,
	FaHtml5,
	FaGithub,
	FaNode,
	FaFigma,
	FaUnity,
	FaAws
} from 'react-icons/fa';
import {
	SiTypescript,
	SiJavascript,
	SiReact, SiCss3,
	SiJira,
	SiDiscord,
	SiCanva,
	SiXcode,
	SiAndroidstudio,
	SiExpo,
	SiFirebase,
	SiTwilio,
	SiPostman
} from 'react-icons/si';
import Marquee from 'react-fast-marquee';
import { useIsMobile } from 'components/isMobile';
import TitleBlurb from 'components/TitleBlurb';

// The icons for the technologies we use
const techIcons = {
	'Java': <FaJava />,
	'Python': <FaPython />,
	'HTML': <FaHtml5 />,
	'CSS': <SiCss3 />,
	'JavaScript': <SiJavascript />,
	'TypeScript': <SiTypescript />,
	'React': <SiReact />,
	'Node.js': <FaNode />,
	'GitHub': <FaGithub />,
	'Figma': <FaFigma />,
	'Unity': <FaUnity />,
	'AWS': <FaAws />,
	'Jira': <SiJira />,
	'Discord': <SiDiscord />,
	'Canva': <SiCanva />,
	'Xcode': <SiXcode />,
	'Android Studio': <SiAndroidstudio />,
	'Expo': <SiExpo />,
	'Firebase': <SiFirebase />,
	'Twilio': <SiTwilio />,
	'Postman': <SiPostman />,
};

/**
 * The tech marquee component.
 * @returns The tech marquee component.
 */
export default function TechMarquee() {

	// Check if the user is on a mobile device.
	const isMobile = useIsMobile();

	return (
		<div
			data-aos='fade'
			style={{
				width: '85%',
				textAlign: isMobile ? 'left' : 'center'
			}}
			className='mx-auto flex flex-col justify-center py-20 lg:mt-0 max-w-7xl'
		>

			<div className='TechWeUse'/>

			{/* Header */}
			<div
				className='flex flex-col items-center max-w-screen-2xl justify-self-center'
			>
				<TitleBlurb
					title='Tech We Use'
					// eslint-disable-next-line max-len
					blurb='We leverage cutting-edge tools and frameworks to build robust, scalable, and efficient software solutions tailored to your unique needs.'
				/>
			</div>

			{/* Top Marquee */}
			<div data-aos='fade' className='py-4'>
				<Marquee gradient={true} gradientWidth={50} speed={35} direction='left'>
					{Object.entries(techIcons).slice(0, Math.ceil(Object.entries(techIcons).length / 2)).map(([ tech, icon ], index) => {
						return (
							<div key={index} className='flex flex-col items-center justify-center mx-4'>
								<div className='w-24 h-16 flex items-center justify-center'>
									{React.cloneElement(icon, { className: 'w-16 h-16 text-light-blue-500' })}
								</div>
								<LeadText color='blueGray'>{tech}</LeadText>
							</div>
						);
					})}
				</Marquee>
			</div>

			{/* Bottom Marquee */}
			<div data-aos='fade' className='py-4'>
				<Marquee gradient={true} gradientWidth={50} speed={45} direction='left'>
					{Object.entries(techIcons).slice(Math.ceil(Object.entries(techIcons).length / 2)).map(([ tech, icon ], index) => {
						return (
							<div key={index} className='flex flex-col items-center justify-center mx-4'>
								<div className='w-24 h-16 flex items-center justify-center'>
									{React.cloneElement(icon, { className: 'w-16 h-16 text-light-blue-500' })}
								</div>
								<LeadText color='blueGray'>{tech}</LeadText>
							</div>
						);
					})}
				</Marquee>
			</div>

		</div>
	);
}
