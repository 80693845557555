import React, { useReducer } from 'react';
import Input from '@material-tailwind/react/Input';
import Textarea from '@material-tailwind/react/Textarea';
import Button from '@material-tailwind/react/Button';
import LeadText from '@material-tailwind/react/LeadText';
import emailjs from 'emailjs-com';
import TitleBlurb from 'components/TitleBlurb';
import 'assets/styles/navbar.css';

const initialState = {
	nameColor: true,
	emailColor: true,
	messageColor: true,
	sentColor: false,
};

/**
 * Reducer fuctuion for the contact form.
 * @param state - The current state of the form.
 * @param action - The action to be processed by the reducer.
 * @returns The updated state based on the action.
 */
function reducer(state, action) {
	switch (action.type) {
	case 'TOGGLE_NAME':
	case 'TOGGLE_EMAIL':
	case 'TOGGLE_MESSAGE':
	case 'TOGGLE_SENT': {
		const key = `${action.type.split('_')[1].toLowerCase()}Color`;
		return { ...state, [key]: action.value !== undefined ? action.value : !state[key] };
	}
	default:
		return state;
	}
}

/**
 * the compoent to send a form
 * @returns the form compoent
 */
function Form() {
	const [ state, dispatch ] = useReducer(reducer, initialState);
	const [ errorMessage, setErrorMessage ] = React.useState('');

	/**
	 * Resets the colors of the form fields to their initial state.
	 */
	function resetColors() {
		dispatch({ type: 'TOGGLE_NAME', value: true });
		dispatch({ type: 'TOGGLE_EMAIL', value: true });
		dispatch({ type: 'TOGGLE_MESSAGE', value: true });
	}

	/**
	 * Handles input change events for form fields.
	 * @param e - The event object.
	 */
	function handleInputChange(e) {
		const { id, value } = e.target;
		if (value.trim() !== '') {
			dispatch({ type: `TOGGLE_${id.toUpperCase()}`, value: true });
		}
	}

	/**
	 * Gets the color based on the state key.
	 * @param stateKey - The key of the state to get the color for.
	 * @returns - The color corresponding to the state key.
	 */
	function getColor(stateKey) {
		return state[`${stateKey}Color`] ? 'lightBlue' : 'red';
	}

	/**
	 * Gets the color for the sent message status.
	 * @returns - The color corresponding to the sent message status.
	 */
	function getSentColor() {
		return state.sentColor ? 'green' : 'red';
	}

	/**
	 * Gets the visibility style for the send button.
	 * @returns - The display style for the send button.
	 */
	function getBtnVis() {
		return state.sentColor ? 'none' : 'inline';
	}

	/**
	 * Sends an email using the form data.
	 * @param e - The event object.
	 */
	function sendEmail(e) {
		e.preventDefault();

		const nameInput = document.getElementById('name');
		const emailInput = document.getElementById('email');
		const messageInput = document.getElementById('message');

		let isValid = true;
		let firstEmptyField = null;

		if (!nameInput.value.trim()) {
			dispatch({ type: 'TOGGLE_NAME', value: false });
			isValid = false;
			if (!firstEmptyField) {
				firstEmptyField = nameInput;
			}
		}
		if (!emailInput.value.trim()) {
			dispatch({ type: 'TOGGLE_EMAIL', value: false });
			isValid = false;
			if (!firstEmptyField) {
				firstEmptyField = emailInput;
			}
		}
		if (!messageInput.value.trim()) {
			dispatch({ type: 'TOGGLE_MESSAGE', value: false });
			isValid = false;
			if (!firstEmptyField) {
				firstEmptyField = messageInput;
			}
		}

		if (!isValid) {
			setErrorMessage('Please fill out all fields to send a message');
			if (firstEmptyField) {
				firstEmptyField.focus();
			}
			return;
		}

		setErrorMessage('');

		emailjs.sendForm('service_bgztbtm', 'template_26u333e', document.getElementById('emailForm'), 'user_mnn1kQUYgGyyu0PPDKMN4')
			.then((result) => {
				console.log(result.text);
				resetColors();
				dispatch({ type: 'TOGGLE_SENT', value: true });
				nameInput.value = '';
				emailInput.value = '';
				messageInput.value = '';
				document.getElementById('sendButton').textContent = 'Message Sent';
				setErrorMessage('Thank you! Your message has been sent. We will get back to you within 24 hours');
			})
			.catch((error) => {
				setErrorMessage(`Sending Message Failed: ${error.text}`);
			});
	}

	return (
		<div id='contact-section' className='flex flex-wrap justify-center'>
			<div className='w-full lg:w-8/12 px-4'>
				<div className='relative flex flex-col min-w-0 break-words w-full mb-6'>
					<div className='flex-auto p-5 lg:p-10'>
						<TitleBlurb
							title='Contact Us'
							blurb='Complete this form and we will get back to you in 24 hours.'
						/>
						<form id='emailForm' onSubmit={sendEmail}>
							<div className='flex gap-8 mt-16 mb-12'>
								<Input
									id='name'
									type='text'
									placeholder='Full Name'
									color={getColor('name')}
									name='user_name'
									onChange={handleInputChange}
								/>
								<Input
									id='email'
									type='email'
									placeholder='Email Address'
									color={getColor('email')}
									name='user_email'
									onChange={handleInputChange}
								/>
							</div>

							<Textarea
								id='message'
								color={getColor('message')}
								placeholder='Message'
								name='message'
								onChange={handleInputChange}
							/>

							<div className='flex justify-center text-center'>
								<LeadText
									id='error'
									color={getSentColor()}
								>
									{errorMessage}
								</LeadText>
							</div>

							<div className='flex justify-center mt-10'>
								<Button id='sendButton' color='lightBlue' ripple='light' style={{ display: getBtnVis() }} >
                  Send Message
								</Button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}

/**
 * For some reason we decided to create a React component for the contact section of the landing page.
 * Then instead of exporting the component, we exported the function that creates the component.
 * We are so smart.
 * @returns The contact section of the landing page.
 */
export default function ContactSection() {
	return <Form />;
}
