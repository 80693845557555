import { useState } from 'react';
import Navbar from '@material-tailwind/react/Navbar';
import NavbarContainer from '@material-tailwind/react/NavbarContainer';
import NavbarWrapper from '@material-tailwind/react/NavbarWrapper';
import NavbarBrand from '@material-tailwind/react/NavbarBrand';
import NavbarToggler from '@material-tailwind/react/NavbarToggler';
import NavbarCollapse from '@material-tailwind/react/NavbarCollapse';
import Nav from '@material-tailwind/react/Nav';
import NavLink from '@material-tailwind/react/NavLink';
import Dropdown from '@material-tailwind/react/Dropdown';
import Button from '@material-tailwind/react/Button';
import { scrollToSection } from 'components/scrollToSection';
import { DropdownLink } from '@material-tailwind/react';
import BlueLogo from '../assets/img/LogoBlue.png';
import { useNavigate } from 'react-router-dom';
import 'assets/styles/navbar.css';
import 'aos/dist/aos.css';
import { useIsMobile } from './isMobile';
import NavigationDrawer from './NavigationDrawer';
import { FileText, MessageSquare, MonitorSmartphone, Users } from 'lucide-react';
import { SiFacebook, SiInstagram, SiLinkedin } from 'react-icons/si';

const GROUP_INDEX = {
	GENERAL: 0,
	SOCIALS: 1,
};

/**
 * The default navbar for the landing page.
 * @returns The default navbar for the landing page.
 */
export default function DefaultNavbar() {
	const [ openNavbar, setOpenNavbar ] = useState(true);

	const isMobile = useIsMobile();
	const navigate = useNavigate();

	const buttonGroups = [
		{ title: 'General', buttons: [
			{ label: 'About Us', icon: FileText, onClick: () => {
				return navigate('/about');
			} },
			{ label: 'Contact Us', icon: MessageSquare, onClick: () => {
				if(window.location.pathname !== '/') {
					return navigate('/#contact-section');
				}
				return scrollToSection('contact-section');
			} },
			{ label: 'Projects', icon: MonitorSmartphone, onClick: () => {
				return navigate('/projects');
			} },
		] },
		{ title: 'Socials', buttons: [
			{ label: 'Instagram', icon: SiInstagram, onClick: () => {
				return window.open('https://www.instagram.com/zenithsoftwaredev/', '_blank');
			} },
			{ label: 'Facebook', icon: SiFacebook, onClick: () => {
				return window.open('https://www.facebook.com/Zenith-Software-100289855796121', '_blank');
			} },
			{ label: 'LinkedIn', icon: SiLinkedin, onClick: () => {
				return window.open('https://www.linkedin.com/company/zenith-software-development', '_blank');
			} }
		] },
	];

	return (
		<div data-aos='fade-down'>
			<Navbar color='transparent' navbar>
				<NavbarContainer>
					{/* Override lg breakpoints with md */}
					<NavbarWrapper className='md:w-auto md:static md:block md:justify-start'>
						<a
							href='/'
							target='_self'
							rel='noreferrer'
						>

							<div className='flex'>

								<img src={BlueLogo} alt='Zenith Software Development Logo' className='logo'></img>

								<div className='zenith-words hidden lg:block'>
									<NavbarBrand>Zenith Software</NavbarBrand>
								</div>

							</div>
						</a>
						{isMobile &&
							<NavbarToggler
								onClick={() => {
									setOpenNavbar(!openNavbar);
								}}
								color='white'
							/>
						}
					</NavbarWrapper>

					{/* NavbarCollapse will only show on desktop. Mobile uses NavigationDrawer (at the bottom of this page) */}
					<NavbarCollapse className={'md:flex'} open={!isMobile}>
						<Nav className={'md:items-center md:flex-row'}>
							<div className='flex flex-row flex-wrap z-50 md:items-center'>
								{/* All these buttons require style overrides because the Button class forces certain margins */}
								{buttonGroups[GROUP_INDEX.GENERAL].buttons.map((button, index) => {
									return(
										<Button
											key={index}
											color='white'
											size='sm'
											style={{ fontSize: '14px' }}
											ripple='light'
											onClick={button.onClick}
										>
											<button.icon size={24} />
											{button.label.split(' ')[0]}
										</Button>
									);
								})}

								<NavLink className='p-0 m-0' style={{ fontSize: '14px' }} >
									<Users size={24} />
									<Dropdown
										color='white'
										buttonText='SOCIALS'
										buttonType='link'
										size='sm'
										ripple='light'
										style={{ paddingLeft: 4 }}
									>
										{buttonGroups[GROUP_INDEX.SOCIALS].buttons.map((button, index) => {
											return (
												<DropdownLink
													key={index}
													href='#'
													color='lightBlue'
													ripple='light'
													target='_blank'
													onClick={button.onClick}
												>
													<div className='flex items-center'>
														<button.icon size={24} />
														<span className='ml-2'>{button.label}</span>
													</div>
												</DropdownLink>
											);
										})}
									</Dropdown>
								</NavLink>
							</div>
						</Nav>
					</NavbarCollapse>
				</NavbarContainer>
			</Navbar>
			{isMobile &&
				<NavigationDrawer
					isOpen={openNavbar}
					onClose={() => {
						return setOpenNavbar(!openNavbar);
					}}
					buttonGroups={buttonGroups}
				/>
			}
		</div>
	);
}

