import OurImpactCard from "components/projects/OurImpactCard";
import TitleBlurb from "components/TitleBlurb";


/**
 * The OurImpactSection
 * @returns The OurImpactSection with its components.
 */
export default function OurImpactSection({ stepCardInfo }) {
	return (
		<section className="bg-gray-100 py-20">
			<div className="OurImpact mx-auto flex flex-col items-center justify-center max-w-6xl w-full h-100 px-5">

				{/* Header */}
				<TitleBlurb title="Our Impact" blurb="Take a look at how our specialized team handled the requirements of this project." />

				<div className="h-auto flex flex-col items-center justify-center container w-full gap-8 md:gap-20">
					{stepCardInfo.map((project, index) => (
						<OurImpactCard
							key={index}
							title={project.title}
							description={project.description}
							altText={project.altText}
							imageSrc={project.imageSrc}
							variant={index % 2 === 0 ? "left" :"right"}
						/>
					))}
				</div>
			</div>
	</section>
	);
}