import DefaultNavbar from 'components/DefaultNavbar';
import DefaultFooter from 'components/DefaultFooter';
import WorkingSection from 'components/landing/WorkingSection';
import TeamSection from 'components/landing/TeamSection';
import ContactSection from 'components/landing/ContactSection';
import ScrollTopButton from 'components/landing/ScrollTopButton';
import 'assets/styles/entirePage.css';
import HeaderSection from 'components/HeaderSection';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { scrollToSection } from 'components/scrollToSection';


/**
 * The landing page of the website.
 * @returns The landing page with its components.
 */
export default function Landing() {

	const location = useLocation();

	useEffect(() => {
		if(location.hash) {
			scrollToSection(location.hash.substring(1), { duration: 1500 });
		}
	}, [ location ]);

	return (
		<div className='hideOverflow'>
			<div className='absolute w-full z-20'>
				<DefaultNavbar />
			</div>
			<main>
				<HeaderSection
					backgroundClass='landing-background'
					pageTitle='Welcome to Zenith Software!'
					// eslint-disable-next-line max-len
					subtext={'Here at Zenith, we specialize in delivering dynamic and innovative web/mobile solutions to our customers. \n\n Big or small, we will help bring your ideas to life!'}
				/>
				<WorkingSection />
				<TeamSection />
				<ContactSection />
				<ScrollTopButton />
			</main>
			<DefaultFooter />
		</div>
	);
}
