import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Landing from 'pages/Landing';
import AboutUs from 'pages/AboutUs';
import Projects from 'pages/Projects';
import reportWebVitals from 'reportWebVitals';
import ReactGA from 'react-ga4';
import AOS from 'aos';
import '@fortawesome/fontawesome-free/css/all.min.css';

import 'assets/styles/tailwind.css';
import 'assets/styles/bios.css';
import ProjectDescription from 'pages/ProjectDescription';

if (window.location.hostname !== 'localhost') {
	ReactGA.initialize('G-DZ58WJHB4Y');
	console.log('CONNECTED');
} else {
	console.log('ANALYTICS NOT REPORTED - USER ON LOCALHOST');
}
ReactGA.send('pageview');

AOS.init({ duration: 700 });

const router = createBrowserRouter([
	{
		path: '/about',
		element: <AboutUs />,
	},
	{
		path: '/projects',
		element: <Projects />,
	},
	{
		path: '/projectdescription',
		element: <ProjectDescription />,
	},
	{
		path: '*',
		element: <Landing />,
	},
]);

ReactDOM.render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
