import "assets/styles/bios.css";
import "aos/dist/aos.css";
import { useIsMobile } from "components/isMobile";

/**
 * The Image component
 * @param {string} src The source of the image.
 * @returns The image component.
 */
const Image = ({ src }) => (
	<div className="h-full flex items-center justify-center">
		<img className="rounded-2xl w-full h-auto object-cover" src={src} alt="Project" />
	</div>
);

/**
 * The Info Section
 * @param {string} title The title of the project.
 * @param {string} description The description of the project.
 * @param {string} color The color of the button.
 * @param {string} buttonLink The link of the button.
 * @returns The info section with the information about the project.
 */
function InfoSection({ title, description, color, buttonLink }) {
	const isMobile = useIsMobile();
  
	return (
		<div className="flex flex-col h-full justify-between">
			<div>
				<div className="mb-4">

					{/* Title */}
					<p
						className="text-2xl font-semibold font-serif pb-4"
						data-aos="fade-up"
						data-aos-delay="100"
					>
						{title}
					</p>

					{/* Description */}
					<p
						className="text-xl md:text-2xl font-light leading-relaxed"
						data-aos="fade-up"
						data-aos-delay="200"
					>
						{description}
					</p>
				</div>
			</div>

			{/* Button */}
			<div className="mt-6" data-aos="fade-up" data-aos-delay="200">
				<a
					href={buttonLink}
					rel="noopener noreferrer"
					className={`${
					isMobile ? 'w-full' : 'w-2/3'
					} inline-block text-center text-white py-4 px-10 rounded-lg font-bold uppercase transition-transform duration-300 hover:scale-105`}
					style={{ backgroundColor: color }}
				>
					Learn More
				</a>
			</div>
		</div>
	);
  }

/**
 * The Project Card component
 * @param {string} title The title of the project.
 * @param {string} description The description of the project.
 * @param {string} buttonColor The color of the button.
 * @param {string} buttonLink The link of the button.
 * @param {string} imageSrc The source of the image.
 * @param {boolean} inverted The boolean to determine if the card is inverted.
 * @returns The project card component.
 */
export default function ProjectCard({ title, description, buttonColor, buttonLink, imageSrc, inverted = false }) {

	const maxWidth = "max-w-6xl";

	return (
		<div
			data-aos={`fade-${inverted ? "left" : "right"}`}
			className={`flex flex-col ${inverted ? "md:flex-row-reverse" : "md:flex-row"} lg:mb-0 mb-12 w-full ${maxWidth} gap-8 md:gap-16 justify-center items-stretch self-center`}
		>
			{/* Image */}
			<div className="flex-1 min-w-0">
				<Image src={imageSrc} />
			</div>

			{/* Info */}
			<div className="flex-1 min-w-0">
				<InfoSection
					title={title}
					description={description}
					color={buttonColor}
					buttonLink={buttonLink}
				/>
			</div>
		</div>
	);
};
