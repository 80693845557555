import "assets/styles/background.css";
import "assets/styles/projects.css";
import "aos/dist/aos.css";
import React from "react";
import { useIsMobile } from "components/isMobile";
import "assets/styles/headerStyles.css";

/**
 * The header component for the projects page.
 * @returns The header component of the projects page to render.
 */
export default function HeaderSection({pageTitle, subtext, backgroundClass}) {

	const isMobile = useIsMobile();

	return (
		<div className={
                `flex
                ${backgroundClass}
                page-background
                bg-cover
                bg-center
                justify-center
                content-center items-center justify-center
                ${isMobile ? "" : "h-screen"}`
            }>
            <div className={`max-w-7xl flex items-center pt-24 font-serif gap-16 ${isMobile ? "flex-wrap px-8 gap-4 pb-32 text-center" : "px-16 pb-32 text-left"}`}>
                <h2 color={"white"} className={`text-white font-bold leading-normal w-full ${isMobile ? "header-title-mobile" : "header-title-desktop text-5xl "}`}>
                    {pageTitle}
                </h2>
                <p className="w-full h-full self-start text-gray-200 text-lg font-light header-subtext pt-4">
                    {subtext}
                </p>
            </div>
		</div>
	);
}
