import H2 from '@material-tailwind/react/Heading2';
import '../../assets/styles/mycssfile.css';
import PhoneImage from '../../assets/img/zenithsiteiphone.png';
import ViewOurWork from '../ViewOurWorkButton';
import { useIsMobile } from 'components/isMobile';
import { useNavigate } from 'react-router-dom';

/**
 * The component is for the view our work section on the landing page.
 * @param props - The props object.
 * @param props.titleText - The main title text.
 * @param props.titleText2 - The secondary title text.
 * @param props.buttonText - The text for the button.
 * @param props.link - The link for the button.
 * @param props.phonePictureSrc - The picture to be displayed on the phone. If not provided, the phone will have a placeholder image.
 * @returns The View Our Work Section
 */
export default function ViewOurWorkSection({ titleText, titleText2, buttonText, link, phonePictureSrc }) {


	const isMobile = useIsMobile();
	const navigate = useNavigate();

	console.log(phonePictureSrc);

	// eslint-disable-next-line max-len
	const maskString = 'url(\'data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 705 940"%3E%3Cpath d="M38 123c0-81 55-89 80-90 34 0 88-1 93-1s12 2 12 12c0 4 0 6 0 9 1 23 21 34 35 34H440c34 0 42-27 41-37V43c0-5 3-11 13-11h80c88 0 92 59 92 83 0 49 1 823 1 863-47 0-446-1-627-2Z"/%3E%3C/svg%3E\')';

	return (
		<section className='w-full bg-light-blue-500 pt-16'>
			<div className='container max-w-7xl mx-auto px-4 flex justify-center flex-wrap gap-10'>
				<div
					data-aos='fade'
					className='h-full justify-center'
					style={{ height:'100%', margin: 'auto', marginLeft: 0, marginRight: 0 }}>
					<H2	color='white' style={isMobile ? { fontSize:32, textAlign: 'center' } : {}}>
						{titleText} <br/> {titleText2}
					</H2>
					<div className='w-full'>
						<ViewOurWork className={isMobile ? 'mx-auto mt-8 mb-8 pt-4 pb-4 w-64 text-lg' : 'mt-16 mb-16 pt-6 pb-6 w-64 text-lg'}
							onClick={
								() => {
									if(link) {
										window.open(link, '_blank');
									} else {
										navigate('/projects');
									}
								}
							}>
							{buttonText}
						</ViewOurWork>
					</div>
				</div>

				<div className={'container w-full md:w-1/3 relative z-0 overflow-hidden'} data-aos='fade-up'>
					{/* Height wrapper for the images */}
					<div className='w-full'>
						{/* Base image */}
						<img
							src={PhoneImage}
							alt='Base'
							className='w-full'
							style={{
								position: 'absolute',
								top: 0,
								left: 0,
								zIndex: 1,
							}}
						/>

						{/* Masked image */}
						{phonePictureSrc &&
						<div
							style={{
								position: 'absolute',
								width: '100%',
								height: '100%',
								zIndex: 1,
								// Using webkit and non-webkit for compatibility, just in case.
								WebkitMaskImage: maskString,
								maskImage: maskString,
								maskRepeat: 'no-repeat',
								maskSize: 'cover',
							}}>
							<img
								src={phonePictureSrc}
								alt='Masked'
								className='w-full h-full'
								style={{
									top: 0,
									left: 0,
									flex: 1,
									zIndex: 30,
									backgroundColor: 'black',
									objectFit: 'cover',
									objectPosition: 'top center',
									padding: '15px 17px 0px 17px', // Make image sit within bezel
								}}
							/>
						</div>}
					</div>
				</div>
			</div>
		</section>
	);
}
