/**
 * Reports web vitals metrics.
 *
 * This function takes a callback function as an argument and, if the callback is a function,
 * dynamically imports the 'web-vitals' library. It then uses the imported functions to measure
 * various web vitals metrics and passes the results to the callback function.
 * @param onPerfEntry - A callback function that will be called with the performance entries.
 */
function reportWebVitals(onPerfEntry) {
	if (onPerfEntry && onPerfEntry instanceof Function) {
		import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
			getCLS(onPerfEntry);
			getFID(onPerfEntry);
			getFCP(onPerfEntry);
			getLCP(onPerfEntry);
			getTTFB(onPerfEntry);
		});
	}
}

export default reportWebVitals;
