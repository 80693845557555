import React from 'react';
import { Card, CardBody } from "@material-tailwind/react";
import ViewOurWorkButton from './ViewOurWorkButton';
import H2 from '@material-tailwind/react/Heading2';
import { useIsMobile } from './isMobile';

/**
 * ProjectCard component represents a card with a call to action to contact us.
 * @returns {JSX.Element} The rendered ProjectCard component.
 */
function YourProjectHere() {
  const isMobile = useIsMobile();

  return (
    <Card className="mx-auto bg-light-blue-500 max-w-6xl">
      <CardBody className="flex flex-col items-center justify-center text-center py-8">
        {/* Ideally we would have fontSize being controlled with tailwind, but the Material Tailwind elements are not easy to override with just the className */}
        <H2 style={isMobile ? { fontSize: "1.5rem" } : {}} color="white">
          Your Project Here.
        </H2>
        <H2 style={isMobile ? { fontSize: "1.5rem" } : {}} color="white">
          Let's Make it Happen.
        </H2>
        <ViewOurWorkButton
          className="mt-10"
          link="/#contact-section"
        >
          Contact Us
        </ViewOurWorkButton>
      </CardBody>
    </Card>
  );
}

export default YourProjectHere;
